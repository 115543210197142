import React from 'react';
import './Container.css'; // Import the CSS file

const Container = () => {
    return (
        <div className="container">
            <div className="page">
                
                {/* Left Side */}
                <div className="left-side">
                    <div className="section">
                        <h1>CHIEF PATRONS</h1>
                      <ul> <li> Shri K N Suhane (Chairman VGI Bhopal)</li></ul>
                    </div>
                    <div className="section">
                        <h1>PATRONS</h1>
                        <ul>
                            <li>Er. Ashish Suhane (Executive Secretary VGI)</li>
                            <li>Smt. Shraddha Suhane (CEO VGI)</li>
                        </ul>
                    </div>
                    <div className="section">
                        <h1>CONVENORS (IC RAETHM-2024)</h1>
                        <ul>
                            <li>Mrs. Vandana Sharma (Sr. Technical Advisor, AEG)</li>
                            <li>Ms. Yamini Singhal (Manager, AEG)</li>
                            <li>Ms. Bharti Soni (Advertising Team, AEG)</li>
                        </ul>
                    </div>
                   
                            
                  
                    {/* <div className="section">
                        <h1>ORGANIZING COMMITTEE</h1>
                        <ul>
                            <li>Prof. Arun Patel (HOD, Dept. of Mechanical Engineering VIST)</li>
                            <li>Prof. Kumar Sanu (HOD, Dept. of Civil Engineering VIST)</li>
                            <li>Prof. Priya Sinha (HOD, Dept. of Computer Science & Engineering VIST)</li>
                            <li>Prof. Dr. Nidhi Saxena (Dept. of Education VGI)</li>
                        </ul>
                        <ul>
                            <li>Prof. Aditya Mishra (HOD, Dept. of Electronics & Comm. Engineering VIST)</li>
                            <li>Prof. Mrintujay Piplaha (HOD, Dept. of Electrical & Elec. Engineering VIST)</li>
                            <li>Prof. Rashmi Aswani (HOD, Dept. of Management VIST)</li>
                            <li>Prof. Kailash Sahu (HOD, Department of Pharmacy VGI)</li>
                        </ul>
                    </div> */}
                    
                </div>

                {/* Right Side */}
                <div className="right-side">
                    <div className="section">
                        <h1>ADVISORS</h1>
                        <ul>
                            <li>Prof. (Dr.) Rajeev Arya (Director VIST Bhopal)</li>
                            <li>Mr. Sharad Sharma (Director, AEG Consultancy Services Pvt. Ltd.)</li>
                        </ul>
                    </div>
                    <div className="section">
                        <h1>COORDINATOR (IC RAETHM-2024)</h1>
                        <ul>
                            <li>Prof. Arvind Gupta (Dept. of Elex & Elec. Engg. VIST, Bhopal)</li>
                        </ul>
                    </div>   
                    <div className="section">
                        <h1>CHIEF ADVISORS</h1>
                        <ul>
                            <li>Dr. Payal Suhane (Director VGI)</li>
                        </ul>
                    </div>
                   
                   
                </div>
            </div>
        </div>
    );
}

export default Container;
